import React, { useEffect, useState } from "react";
import axios from "axios";
import "./App.css";
import io from "socket.io-client";
const socket = io("https://api.regnodeicieli.cc");

function App() {
  const [cameras, setCameras] = useState([]);

  const handleCameraUpdate = () => {
    // Get cameras from API and set it to state
    axios
      .get("https://api.regnodeicieli.cc/api/cameras", {
        headers: {
          Authorization: "Bearer " + process.env.REACT_APP_API_KEY,
        },
      })
      .then((response) => {
        const cameras = response.data;
        const camArray = [];
        const currentTime = new Date().getTime();
        cameras.map((camera) => {
          camArray.push(
            `${process.env.REACT_APP_IMAGE_PATH}${camera.suffix_name}?t=${currentTime}`
          );
        });
        setCameras(camArray);
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
  };

  // useEffect(() => {}, [cameras]);

  const handleUpdate = () => {
    handleCameraUpdate();
  };

  //  SOCKET Configuration
  useEffect(() => {
    socket.on("connect", () => {
      console.log("Connected to server");
      handleUpdate();

      const clientData = {
        browser: navigator.userAgent,
        platform: navigator.platform,
        language: navigator.language,
        processor: navigator.hardwareConcurrency,
      };
      socket.emit("clientdata", clientData);
    });

    socket.on("update", handleUpdate);

    return () => {
      socket.off("update", handleUpdate);
    };
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        {/* Iterate over Cameras and create a div, img for each camera */}
        {cameras.map((camera, index) => (
          <div key={index}>
            <img src={camera} alt={`Camera ${camera.name}`} />
          </div>
        ))}
      </header>
    </div>
  );
}

export default App;
